import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    article,
  },
}) {
  return (
    <Layout>
      <Helmet title={`${siteTitle} - ${article.frontmatter.title}`} />
      <div id="pageheader">
        <h1>{article.frontmatter.title}</h1>
      </div>
      <div
        className="blog-article-content"
        dangerouslySetInnerHTML={{ __html: article.html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArticleByPath($id: String!) {
    article: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
